/* eslint-disable no-tabs, max-len,react/jsx-props-no-spreading, react/jsx-first-prop-new-line */
import React from 'react';
import Icon from '@ant-design/icons';

function TreeFilledSvg() {
  return (
    <svg width="1em" height="1em" viewBox="0 0 512 512" fill="currentColor">
      <path fill="currentColor"
        d="M74.96,407.064c3.061-82.464,41.669-165,106.747-225.632c39.177-36.509,84.732-62.947,135.396-78.57
		c0.738-0.232,1.482-0.4,2.228-0.617c-0.697-2.003-1.266-4.07-2.027-6.033C301.112,54.055,270.837,21.384,233.206,0
		c-41.725,12.947-80.005,35.812-112.236,67.626c-32.158,31.798-58.1,72.425-73.186,119.83
		c-15.062,47.389-17.033,94.834-7.604,138.185C46.655,355.124,58.335,382.844,74.96,407.064z"
      />
      <path fill="currentColor"
        d="M460.504,114.246c-45.033-5.744-91.501-1.546-136.15,12.122c-44.665,13.788-87.824,37.615-125.871,73.074
		c-69.901,65.134-104.392,153.439-98.479,236.359c-9.87,7.499-19.845,14.918-30.116,22.24c-10.375,7.283-20.614,14.614-33.392,21.84
		L54.707,512c15.134-8.604,25.717-16.272,36.597-23.891c10.712-7.635,20.942-15.246,31.037-22.922
		c79.099,27.336,181.792,5.84,260.746-67.722c42.83-39.874,72.338-89.547,85.917-140.228
		C482.705,206.604,479.988,155.194,460.504,114.246z M406.778,187.89c-19.974,29.17-50.193,63.972-82.72,98.326
		c28.898-4.735,51.996-9.038,63.06-11.16c4.655-0.889,7.17-1.395,7.178-1.395c4.446-0.889,8.765,1.995,9.654,6.434
		c0.889,4.447-1.995,8.765-6.434,9.654c-0.08,0.016-39.986,7.988-92.302,16.095c-5.768,5.913-11.561,11.777-17.337,17.553
		c-17.61,17.602-35.01,34.386-51.09,49.464c39.554-6.145,80.044-14.372,99.801-18.555c7.852-1.658,12.403-2.668,12.418-2.676
		c4.422-0.977,8.804,1.81,9.79,6.233c0.977,4.423-1.81,8.804-6.233,9.79c-0.121,0.016-74.012,16.456-136.062,24.732l-0.969,0.128
		c-16.232,14.782-30.124,26.888-40.043,35.003v0.008c-3.5,2.868-8.676,2.347-11.544-1.162c-2.868-3.5-2.347-8.676,1.162-11.544
		c10.343-8.461,25.469-21.688,43.183-37.919c0.505-3.557,1.058-8.044,1.634-13.275c0.969-8.782,2.002-19.452,3.02-30.661
		c2.044-22.408,4.006-46.948,5.256-62.988c0.833-10.687,1.346-17.578,1.346-17.593c0.336-4.519,4.27-7.908,8.789-7.571
		c4.519,0.329,7.908,4.27,7.572,8.789c0,0.032-4.615,62.323-8.669,102.317c-0.136,1.338-0.264,2.508-0.4,3.781
		c15.599-14.653,32.456-30.916,49.44-47.901c5.992-5.993,11.993-12.074,17.978-18.203c2.452-9.806,4.246-25.444,5.32-41.116
		c1.194-16.961,1.666-34.105,1.859-45.073c0.128-7.307,0.128-11.833,0.128-11.842c0-4.534,3.669-8.204,8.204-8.204
		s8.204,3.67,8.204,8.204c-0.008,0.144,0.016,41.741-3.509,75.27c-0.072,0.649-0.153,1.266-0.225,1.906
		c31.414-33.312,60.336-66.801,79.003-94.113c2.556-3.741,7.659-4.694,11.401-2.139C408.38,179.044,409.333,184.148,406.778,187.89z
		"
      />
    </svg>
  );
}

export function TreeFilledIcon(props: any) {
  return <Icon component={TreeFilledSvg} className="leaf-icon" {...props} />;
}
